<template>
	<section class="hero new-font">
		<Navbrand />

		<div class="hero-body">
			<div class="container">
				<div class="columns is-vcentered reverse-columns">
					<div class="column is-12-mobile is-6-desktop is-5-widescreen">
						<h1 class="title is-size-2-mobile" v-text="chooseTitle()" />
						<div class="column is-paddingless">
							<p class="subtitle" v-text="chooseSubtitle()" />
						</div>
						<div class="column pl-0 pt-3 centered-btn">
							<GetStartedButton airdropPage :referredBy="referredBy" :customInvite="customInvite"
								:userAlreadyLoggedIn="isUserLoggedInTradingApp()" />
						</div>
					</div>
					<div class="column">
						<figure class="rewards-image">
							<picture>
								<img src="@/assets/img/rewards/img_bonus.svg" :alt="$t('rewards_img')">
							</picture>
						</figure>
					</div>
				</div>
			</div>
		</div>

		<div class="trustpilot-section">
			<div class="container">

				
				<div class="trustpilot-rating">
					<img class="trust-rating-img" src="@/assets/img/rewards/trustpilot_rating.svg" :alt="$t('trustpilot_stars')"  />
					<div class="trust-rating-text">
						<div class="trust-rating-header">
							{{ $t('trust_pilot_rating_header') }}
						</div>
						<div class="trust-rating-detail">
							{{ $t('trust_pilot_rating_text') }}
						</div>
					</div>
				</div>
				<!-- TrustBox widget - Review Collector -->
				<!-- <div class="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc"
					data-businessunit-id="5eb1540be172900001780d00" data-style-height="52px" data-style-width="100%">
					<a href="https://www.trustpilot.com/review/morpher.com" target="_blank" rel="noopener">Trustpilot</a>
				</div> -->
				<!-- End TrustBox widget -->
				
				<img class="trust-img" src="@/assets/img/rewards/img_trustpilot.svg" :alt="$t('trustpilot_logo')" @click="openTrustPilot()" />
			</div>
		</div>
	</section>

</template>
<script>
import Navbrand from "./Navbrand";
import Navbar from "@/components/partials/Navbar";
import GetStartedButton from "@/components/partials/GetStartedButton";
import { isUserLoggedInTradingApp } from '@/utils/helpers';

import { i18n } from "@/plugins/i18n";

export default {
	name: "Hero",
	components: {
		Navbrand,
		GetStartedButton,
	},
	props: {
		referredByName: {
			type: String,
			required: false,
			default: "",
		},
		referredBy: {
			type: String,
			required: false,
			default: "",
		},
		customInvite: {
			type: String,
			required: false,
			default: "",
		},
		mphPrice: {
			type: Number,
			required: false,
			default: 0.015,
		},
		inviteMessage: {
			type: String,
			required: false,
			default: "",
		},


	},
	async created() {
		// load the trust pilot SDK
		// if (!document.getElementById('script_trust_pilot')) {
		// 	const tpScript = document.createElement('script');
		// 	tpScript.setAttribute('src', 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
		// 	tpScript.setAttribute('id', 'script_trust_pilot');
		// 	document.head.appendChild(tpScript);
		// }
	},
	methods: {
		chooseTitle() {

			return i18n.t("reward_h1_1")
		},
		chooseSubtitle() {


			const amount = this.formatUSDEstimate(100000, this.mphPrice, 50)
			let subtitle = i18n.t("reward_sub", { amount });

			return subtitle;
		},
		register() {
			window.location.href = this.registerURL;
		},
		openTrustPilot() {
			window.open('https://www.trustpilot.com/review/morpher.com', '_blank')
		},
		isUserLoggedInTradingApp
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
	background-color: #F3FDF6;
	padding-top: 40px;
}

.hero .title {
	font-weight: 700;
	font-size: 48px;
}


/* **************************** */
.hero .subtitle {
	line-height: 1.5;
	font-weight: 300;
	font-size: 20px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-5 {
	padding-top: 5px;
}

.mt-20-tablet {
	margin-top: 20px;
}

.mt-55-tablet {
	margin-top: 30px;
}

.centered {
	display: flex;
	justify-content: center;
	align-items: center;
}

.spacing-featured {
	margin-top: 40px;
}

.rewards-image {
	margin: 0 auto;
	max-width: 580px;
}

@media only screen and (min-width: 1408px) {
	.referral-spacing {
		margin-bottom: 36px;
	}
}

@media only screen and (max-width: 1023px) {
	.is-center-touch {
		display: flex;
		justify-content: center;
		align-items: center;
	}

}

.trust-img {
	position: absolute;
	right: 50px;
	top: 5px;
	cursor: pointer;
}

.trustpilot-section {
	background: #FAF9F8;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 32px 0;
}
.trustpilot-rating {
	display: flex;
}
.trust-rating-text {
	margin-left: 10px;
}
.trust-rating-header {
	font-weight: 700;
	font-size: 18px;
}
.trust-rating-detail {
	font-weight: 400;
	font-size: 15px;

}
@media only screen and (max-width: 850px) {
	.trustpilot-rating {
		margin-left: auto;
		margin-right: auto;
		margin-top: 5px;
		align-items: center;
        justify-content: center;
        flex-wrap: wrap;
	}
	.trustpilot-section {
		padding: 24px 0;
	}
	
	.reverse-columns {
		display: flex;
		flex-direction: column-reverse;
	}
	.centered-btn {
		text-align: center;
	}
	.rewards-image {
		max-width: 400px;
	}

	.hero .title {
		text-align: center;
	}

	.hero .subtitle {
		text-align: center;
		font-size: 20px;
	}

	.trust-img {
		position: inherit;
		top: 0px;
		right: auto;
		margin-left: auto;
		margin-right: auto;
		display: block;
		margin-top: 10px;
	}

}

@media only screen and (max-width: 768px) {
	.is-center-mobile {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.hero.hero.is-fullheight {
		min-height: initial;
	}
	
	.hero-body {
		padding-top: 1.5rem;
		padding-bottom: 0;
	}
}

@media only screen and (max-width: 500px) {
	.trustpilot-rating {
		flex-direction: column;
		text-align: center;
	}
	.trust-rating-header {
		margin-top: 0.5rem;
	}
	
	.market-mobile-hide {
		display: none;
	}

	.text-left-mobile {
		text-align: left !important;
	}

	.mt-55-tablet {
		margin-top: inherit;
	}

	.mt-20-tablet {
		margin-top: inherit;
	}

	.spacing-featured {
		margin-top: -40px;
	}
}

@media only screen and (min-height: 1200px) {
	.hero.is-fullheight {
		min-height: 1200px;
	}
}

.pt-0 {
	padding-top: 0px;
	margin-top: -100px;
}

.pl-0 {
	padding-left: 0px;
}

.pt-35 {
	padding-top: 35px;
}

.mt-10 {
	margin-top: 10px;
}

.is-voffset {
	margin-bottom: -180px;
}

.is-26 {
	max-height: 26px;
}

.input-button {
	border-radius: 5px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border: none;
	background: #00c386;
	font-family: "Roboto", sans-serif;
	font-weight: 600 !important;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	outline: none;
	width: 100%;
}

.input-button:hover {
	cursor: pointer;
	outline: none;
	background: #00d492;
	color: #fff;
	box-shadow: 0px 1px 2px rgba(0, 161, 111, 0.2),
		0px 6px 12px rgba(0, 161, 111, 0.2);
	transform: translateY(0);
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}



</style>

<template>
  <div>
    <div class="nav-wrapper">
      <nav class="navbar navbar-spacing">
        <div class="container">
          <div class="navbar-logo">


            <router-link class="" to="/">
              <img src="@/assets/img/rewards/logo_text_black.svg" alt="Morpher Logo">
            </router-link>
            <a v-if="logoutButton" role="button" class="navbar-burger is-hidden-desktop" data-target="navMenu" 
               aria-label="menu" aria-expanded="false" :class="{'is-active': isOpen}"
               @click="isOpen = !isOpen"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div v-if="logoutButton" class="navbar-end is-hidden-mobile">
            <a class="navbar-item is-size-5" @click="logoutAirdrop()">
              {{ $t('LOGOUT') }}
            </a>
          </div>
          <!-- Mobile Menu Items -->
          <div v-if="logoutButton" class="navbar-menu is-hidden-desktop" :class="{'is-active': isOpen}">
            <a class="navbar-item" @click="logoutAirdrop()">
              {{ $t('LOGOUT') }}
            </a>
          </div>
        </div>
      </nav>

    </div>
    <!-- Empty Div to maintain spacing as navbar goes from relative to fixed position -->
    <div class="reg-pholder" :class="{ 'blog-pholder' : blogCategories, 'is-sticky' : stickyNav }" />
  </div>
</template>

<script>
export default {
  name: "Navbrand",
  props: {

    logoutButton: {
      type: Boolean,
      required: false,
      default: false
    },
    blogCategories: {
			type: Boolean,
			required: false,
      default: false
		},
    stickyNav: {
			type: Boolean,
			required: false,
      default: false
		},
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    logoutAirdrop() {
      localStorage.removeItem('uuid_airdrop');
      localStorage.removeItem('kyc_status');
      this.$router.push('/');
    }
  },
};
</script>

<style scoped>
.navbar-spacing {
  padding-top: 12px;
  padding-bottom: 10px;
}
.plus-icon {
  padding: 0 10px;
}
.inner-circle {
  color: #fff;
}
.inner-circle:hover {
  color: #fff;
}
.baskerville {
  font-family: 'Libre Baskerville', serif;
  font-size: 22px;
  margin-left: -2px;
} 
.ic-mobile {
  display: none;
}
.navbar-item a, a.navbar-item {
  font-weight: 600;
  color: #666666;
  transition-delay: 0s;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.25,.46,.45,.94);
}
.navbar-item a:hover, a.navbar-item:hover {
  color: #000000;
  transition-delay: 0s;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.25,.46,.45,.94);
}
/* Mobile Menu Styling */
div.navbar-menu.is-hidden-desktop.is-active {
  box-shadow: 0 4px 10px -4px rgba(182, 191, 210, .3);
  position: absolute;
  width: 100%;
}
@media only screen and (max-width: 850px) {
  .navbar-logo {
    margin-left: auto;
    margin-right: auto;
    display: block;

  }
}

@media screen and (max-width: 767px) {

  .navbar > .container {
    display: flex;
  }
}

@media only screen and (max-width: 475px) {
  .is-hidden-small {
    display: none;
  }
  .ic-mobile {
    margin-top: -1rem;
    padding-top: 0;
    display: inherit;
  }
  .navbar-spacing {
    padding-top: 10px;
  }
}
</style>

<template>
  <section class="section">
    <div class="container">
      <h2 class="title hit-cta has-text-centered" v-html="$t('rewards_how_h2')" />
      <div class="columns is-centered has-text-centered hit-details">
        <!-- Enter Your Email -->
        <div class="column is-3">
          <div class="hit-icon">
            <img src="@/assets/img/rewards/img_complete.svg" :alt="$t('rewards_how1_h3') + ' icon'">
          </div>
          <h3 class="title" v-html="$t('rewards_how1_h3')" />
          <p class="subtitle subtitle-spaced" v-html="$t('rewards_how1_p')" />
        </div>
        <div class="column is-1" />
        <!-- Prove You're Human -->
        <div class="column is-3">
          <div class="hit-icon">
            <img src="@/assets/img/rewards/img_reward.svg" :alt="$t('rewards_how2_h3') + ' icon'">
          </div>
          <h3 class="title" v-html="$t('rewards_how2_h3', {amount:formatUSDEstimate(100000, this.mphPrice, 50)})" />
          <p
            class="subtitle subtitle-spaced"
          >{{ $t('rewards_how2_p', {amount1: formatUSDEstimate(10000, this.mphPrice, 10), amount2: formatUSDEstimate(50000, this.mphPrice, 50), amount3: formatUSDEstimate(100000, this.mphPrice, 50), amount4: formatUSDEstimate(500, this.mphPrice, 1)}) }}</p>
        </div>
        <div class="column is-1" />
        <!-- Get Tokens at Launch -->
        <div class="column is-3">
          <div class="hit-icon">
            <img src="@/assets/img/rewards/img_benefit.svg" :alt="$t('rewards_how3_h3') + ' icon'">
          </div>
          <h3 class="title" v-html="$t('rewards_how3_h3')" />
          <p class="subtitle subtitle-spaced" v-html="$t('rewards_how3_p')" />
        </div>
      </div>

  
    </div>
  </section>
</template>

<script>
export default {
	name: "HowItWorks",
	props: {
		mphPrice: {
			type: Number,
			required: false,
			default: 0.015,
		},
	},
};
</script>

<style scoped>
.section {
    padding-top: 100px;
    padding-bottom: 100px;
}
.title.hit-cta {
	font-weight: 700;
	font-size: 36px;
	margin-bottom: 40px;
}
.hit-details .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}
.subtitle {
  font-weight: 300;
  font-size: 16px;
}
.hit-icon {
	margin-bottom: 10px;
}
.faq {
	margin-top: 2rem;
}
</style>
import axios from "axios";

const client = axios.create();

export default {
	getInviteList() {
		return client.get(process.env.VUE_APP_API + '/web/invites/' );
	},
}

